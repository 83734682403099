import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";

// Connects to data-controller="chart"
export default class extends Controller {
  static targets = ["canvas"];
  static values = {
    index: Number,
  };

  resize() {
    this.chart.resize();
    this.chart.update();
  }

  connect() {
    const colors = [
      "rgba(227, 6, 19, 1)",
      "rgba(249, 178, 51, 1)",
      "rgba(156, 215, 127, 1)",
      "rgba(58, 170, 53, 1)",
      "rgba(0, 102, 51, 1)",
    ];

    const data = {
      datasets: [
        {
          data: [this.indexValue, 5 - this.indexValue],
          backgroundColor: [
            colors[this.indexValue - 1],
            "rgba(102, 102, 102, 0.2)",
          ],
          borderWidth: 0,
          circumference: 180,
          rotation: 270,
          cutout: "30%",
          needleValue: this.indexValue,
        },
      ],
    };

    const gaugeNeedle = {
      id: "gaugeNeedle",
      afterDatasetsDraw(chart, args, plugins) {
        const { ctx, data } = chart;

        ctx.save();
        const xCenter = chart.getDatasetMeta(0).data[0].x;
        const yCenter = chart.getDatasetMeta(0).data[0].y;

        const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
        const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
        const widthSlice = (outerRadius - innerRadius) / 2;
        const radius = 5;
        const angle = Math.PI / 180;

        const needleValue = data.datasets[0].needleValue;
        const dataTotal = data.datasets[0].data.reduce((a, b) => a + b, 0);
        const circumference =
          needleValue === 0
            ? 90
            : (chart.getDatasetMeta(0).data[0].circumference /
                Math.PI /
                data.datasets[0].data[0]) *
              needleValue;

        ctx.translate(xCenter, yCenter);
        ctx.rotate(Math.PI * (circumference + 1.5));

        // Needle
        ctx.beginPath();
        ctx.strokeStyle = "black";
        ctx.fillStyle = "black";
        ctx.moveTo(0 - radius, 0);
        ctx.lineTo(0, 0 - innerRadius - widthSlice);
        ctx.lineTo(radius, 0);
        ctx.stroke();
        ctx.fill();

        // dot
        ctx.beginPath();
        ctx.arc(0, 0, radius, 0, angle * 360, false);
        ctx.fill();

        ctx.restore();
      },
    };

    this.chart = new Chart(this.canvasTarget.getContext("2d"), {
      type: "doughnut",
      reponsive: true,
      maintainAspectRatio: true,
      data,
      options: {
        layout: {
          padding: { bottom: 10 },
        },
        animation: { duration: 0 },
        aspectRatio: 1.8,
        devicePixelRatio: 1.5,
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false },
        },
      },
      plugins: [gaugeNeedle],
    });
  }
}
