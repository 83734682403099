import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="auto-logout"
export default class extends Controller {
  static values = {
    timeout: Number, // Timeout value in milliseconds
  };

  connect() {
    this.resetTimer = this.resetTimer.bind(this);
    this.showWarning = this.showWarning.bind(this);
    this.updateCountdown = this.updateCountdown.bind(this);
    this.logout = this.logout.bind(this);

    this.setTimer();
    this.startListening();
  }

  disconnect() {
    this.stopListening();
    this.clearTimer();
  }

  startListening() {
    ["mousemove", "keydown", "scroll", "click"].forEach((event) => {
      document.addEventListener(event, this.resetTimer);
    });
  }

  stopListening() {
    ["mousemove", "keydown", "scroll", "click"].forEach((event) => {
      document.addEventListener(event, this.resetTimer);
    });
  }

  setTimer() {
    this.clearTimer();
    this.timer = setTimeout(this.showWarning, this.timeoutValue);
  }

  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    if (this.warningTimer) {
      clearTimeout(this.warningTimer);
    }

    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  resetTimer() {
    this.setTimer();
  }

  showWarning() {
    clearTimeout(this.warningTimer);
    const modalElement = document.getElementById("logoutModal");
    modalElement.classList.remove("hidden");

    this.remainingTime = 60;
    this.updateCountdown();
    this.countdownInterval = setInterval(this.updateCountdown, 1000);

    this.warningTimer = setTimeout(this.logout, this.timeoutValue);

    document.getElementById("stay-logged-in").addEventListener(
      "click",
      () => {
        modalElement.classList.add("hidden");
        this.resetTimer();
      },
      { once: true },
    );
  }

  updateCountdown() {
    const countdownElement = document.getElementById("countdown-timer");
    countdownElement.textContent = this.remainingTime;
    this.remainingTime -= 1;

    if (this.remainingTime < 0) {
      clearInterval(this.countdownInterval);
    }
  }

  async logout() {
    const sessionId = document
      .querySelector('meta[name="active-session-id"]')
      .getAttribute("content");
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    const response = await fetch(`/logout?id=${sessionId}`, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    });

    if (response.ok) {
      window.location.href = "/login";
    }
  }
}
